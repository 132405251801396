import { ToWords } from 'to-words'
import moment from 'moment'
import numeral from 'numeral'

export default {
  methods: {
    dateFormatter (value) {
      if (value) {
        return moment(value).format('Y-MM-DD')
      }
      return null
    },

    dateLongFormatter (value) {
      if (value) {
        return moment(value).format('MMMM DD, YYYY')
      }
      return null
    },

    dateShortFormatter (value) {
      if (value) {
        return moment(value).format('MMM DD, YYYY')
      }
      return null
    },

    dateTimeFormatter (value) {
      if (value) {
        return moment(value).format('Y-MM-DD HH:mm:ss')
      }
      return null
    },

    dateTimeShortFormatter (value) {
      if (value) {
        return moment(value).format('MMM DD, YYYY @ LT')
      }
      return null
    },

    dateTimeShortMilitaryFormatter (value) {
      if (value) {
        return moment(value).format('MMM DD, YYYY @ HH:mm')
      }
      return null
    },

    dateTimeLongFormatter (value) {
      if (value) {
        return moment(value).format('MMMM DD, YYYY @ LT')
      }
      return null
    },

    dateTimeLocalFormatter (value) {
      if (value) {
        return moment(value).format('YYYY-MM-DDTHH:mm')
      }
      return null
    },

    numberFormatter (value) {
      if (value) {
        return numeral(value).format('0,0.00')
      }
      return null
    },

    numberToWords (value) {
      const number = new ToWords({
        localeCode: 'en-US',
        converterOptions: {
          currency: true,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
          doNotAddOnly: false,
          currencyOptions: { // can be used to override defaults for the selected locale
            name: 'Peso',
            plural: 'Pesos',
            symbol: '',
            fractionalUnit: {
              name: 'Cent',
              plural: 'Cents',
              symbol: ''
            }
          }
        }
      })
      return number.convert(value)
    },

    objectToUrl (objects) {
      return Object.keys(objects).map(
        key => {
          if (typeof objects[key] === 'undefined' || objects[key] === 'null' || objects[key] === null) {
            return `${key}=`
          }

          if (Array.isArray(objects[key]) > 0) {
            return this.toArrayListParam(Array.from(objects[key]), key)
          }

          return `${key}=${objects[key]}`
        }
      ).join('&').toString()
    },

    toArrayListParam (list, key = 'list') {
      const mapped = list.map(item => {
        return `${key}[]=${item}`
      })

      return mapped.join('&').toString()
    },

    tableNumber (table, index) {
      return Number(table.currentPage) * Number(table.perPage) - Number(table.perPage) + (Number(index) + 1)
    }
  }
}
